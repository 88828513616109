<template>
	<div class="CommodityMerchantList">
		<w-navTab titleText="我的店铺"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="nav-box" v-for="item in merchantList" @click="$router.push({ name: 'CommodityMerchant',params:{id: item.merchant_id}})">
				<div class="nav-box-left">
					<div class="box-left-img"><img :src="item.logo" alt="" /></div>
					<div class="box-left-body">
						<div class="left-body-title">{{item.name}}</div>
						<div class="left-body-text">商品总数：{{item.product_num}}件</div>
					</div>
				</div>
				<div class="nav-box-right"><van-icon name="arrow" size="35" color="#ddd" /></div>
			</div>
		</div>
	</div>
</template>

<script>
	import merchant from '@/api/merchant';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	import Event from '@/store/event';
	export default{
		name: 'CommodityMerchantList',
		data() {
			return{
				listId: '',
				merchantList: '',
				loadingPage: true,
			}
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo;
			}
		},
		created() {
			if(this.$store.state.user.userInfo.merchant_id) {
				this.getList()
			}else{
				Event.$on('onLogin', userInfo => {
					this.getList()
				})
			}
		},
		methods:{
			getList(){
				let id = this.$store.state.user.userInfo.merchant_id.join(',')
				merchant.merchantList({merchant_ids: id})
					.then(result=>{
						this.merchantList = result.data
						this.loadingPage = false
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			}
		},
		components:{
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.CommodityMerchantList{
		.container{
			padding: 10px;
			box-sizing: border-box;
			.nav-box{
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: #FFF;
				padding: 12px;
				border-radius: 8px;
				margin-bottom: 10px;
				box-sizing: border-box;
				.nav-box-left{
					display: flex;
					align-items: center;
					.box-left-img{
						width: 40px;
						height: 40px;
						min-width: 40px;
						img{
							width: 100%;
							height: 100%;
							border-radius: 50%;
							display: block;
							object-fit: cover;
						}
					}
					.box-left-body{
						padding-left: 10px;
						box-sizing: border-box;
						.left-body-title{
							font-size: 16px;
							line-height: 19px;
							color: #222;
						}
						.left-body-text{
							font-size: 12px;
							line-height: 15px;
							color: #777;
							margin-top: 2px;
						}
					}
				}
				.nav-box-right{
					font-size: 12px;
					line-height: 15px;
					color: #777;
				}
			}
		}
	}
</style>